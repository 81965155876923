<script>
import BaseForm from './components/BaseForm'
export default {
  name: 'EditForm',
  components: {
    BaseForm
  },
  data() {
    return {
      progressing: false,
      initializing: true,
      permissions: [],
      model: {
        name: null,
        permissionIds: [],
        superAdmin: false,
        type: 'ORG'
      }
    }
  }
}
</script>
